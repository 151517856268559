import React from "react";
import Seo from "./components/seo/seo";
import Footer from "./components/footer/footer";
import Info from './components/info/info'
import HomeIcon from "@mui/icons-material/Home";
import { navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import {
  mainContainer,
  text,
  footer,
  image,
  icon,
} from "../../styles/porcelanosa.module.css";

function Porcelanosa() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPagePorcelanosa {
        nodes {
          title
          description {
            raw
          }
          imageprincipal {
            gatsbyImageData(placeholder: BLURRED, resizingBehavior: FILL)
          }
        }
      }
    }
  `);

  return (
    <div className={mainContainer}>
      <div className={icon} onClick={() => navigate("/")}>
        <HomeIcon color="primary" fontSize="large" />
        <p>Home</p>
      </div>
      <h1 className="left">
        <a href='https://www.porcelanosa.com/fr/'>{data.allContentfulPagePorcelanosa.nodes[0].title}</a>
      </h1>

      <GatsbyImage
        image={
          data.allContentfulPagePorcelanosa.nodes[0].imageprincipal
            .gatsbyImageData
        }
        alt={`salle de bain porcelanosa`}
        className={image}
      />

      <div className={text}>
        <p>
          En tant que partenaire de Porcelanosa, Herlea BTP dispose d'un
          showroom à Levallois-Perret, en France, où les clients peuvent
          découvrir les différents produits proposés par Porcelanosa, notamment
          les carrelages pour la salle de bain, la cuisine et les espaces
          extérieurs. Herlea BTP est fière de proposer une large gamme de
          produits Porcelanosa pour vous accompagner dans vos projets de
          rénovation de salle de bain et d'aménagement de votre intérieur.
        </p>
      </div>
      <div className={text}>
        <p>
          Porcelanosa est une entreprise espagnole de renom qui propose une
          large gamme de produits pour l'aménagement intérieur et extérieur.
          Parmi ses marques les plus connues, on retrouve Porcelanosa, L'Antic
          Colonial, Butech, Krion, Noken, Xtone et Gamadecor. Porcelanosa est
          reconnue pour son expertise dans la production de carrelages en
          céramique, de meubles et d'équipements pour les salles de bains et les
          cuisines.
        </p>
      </div>
      <h2 className="right"><a href='https://www.xtone-surface.com/fr'>XTONE</a></h2>
      <div className={text}>
        <p>
          Xtone est une division de Porcelanosa qui se concentre sur la
          production de surfaces en céramique ultra-compacte, avec des
          carrelages de grands formats de 120x120 cm et 120x250 cm. L'usine de
          Xtone est située à Vila-Real, en Espagne, où elle utilise une
          technologie de pointe pour produire des dalles de céramique
          ultra-compacte de haute qualité pour une utilisation intérieure et
          extérieure. Les dalles de céramique Xtone sont connues pour leur
          résistance aux rayures, aux taches et aux UV, ainsi que pour leur
          durabilité.
        </p>
      </div>
      <h2 className="left"><a href='https://www.gama-decor.com/fr/'>Gamadecor</a></h2>
      <div className={text}>
        <p>
          Gamadecor est la marque de Porcelanosa spécialisée dans la production
          de meubles pour la cuisine et la salle de bain. Gamadecor propose une
          large gamme de meubles de salle de bain, y compris des colonnes, des
          armoires et des miroirs. Les meubles de Gamadecor sont connus pour
          leur design élégant et leur qualité exceptionnelle.
        </p>
      </div>
      <h2 className="right"><a href='https://www.noken.com/fr_fr'>Noken</a></h2>
      <div className={text}>
        <p>
          Noken est une marque spécialisée dans la production d'équipements pour
          la salle de bain, tels que des robinets, des receveurs de douche, des
          toilettes et des baignoires. Les produits Noken sont connus pour leur
          design élégant et leur qualité exceptionnelle, et sont disponibles
          dans une variété de styles. Les équipements Noken sont conçus pour
          offrir une expérience de salle de bain moderne et confortable, en
          utilisant des technologies innovantes pour réduire la consommation
          d'eau et d'énergie.
        </p>
      </div>
      <h2 className="left"><a href='https://www.anticcolonial.com/fr/'>Lantic Colonial</a></h2>
      <div className={text}>
        <p>
          L'Antic Colonial est une marque spécialisée dans la production de
          matériaux naturels pour l'aménagement intérieur et extérieur. L'Antic
          Colonial propose une large gamme de produits naturels, tels que des
          carreaux en pierre naturelle tel que le marbre, le travertin,
          l'ardoise, du parquet en bois stratifié et contre collé, des mosaïques
          en verre, des revêtements muraux airslate en pierre Naturelle, des
          accessoires de salle de bain et bien plus encore. Les produits L'Antic
          Colonial sont connus pour leur qualité exceptionnelle et leur
          esthétique naturelle, qui ajoute une touche unique de chaleur et
          d'élégance à tout projet d'aménagement d'interieur.
        </p>
      </div>
      <div className={text}>
        <p>
          En somme, les différentes marques de Porcelanosa, dont Porcelanosa,
          L'Antic Colonial, Noken, Butech, Krion, Xtone et Gamadecor, offrent
          une large gamme de produits de haute qualité pour l'aménagement
          intérieur et extérieur. Les produits Porcelanosa sont disponibles dans
          une variété de styles et de finitions pour répondre aux besoins de
          tous les projets de construction, et de rénovation. En utilisant les
          produits Porcelanosa, les clients peuvent créer des espaces modernes,
          sophistiqués et fonctionnels dans leur maison.
        </p>
      </div>
      <div className={footer}>
        <Info />
        <Footer />
      </div>
    </div>
  );
}

export default Porcelanosa;

export const Head = () => <Seo tilte="Herlea BTP | Porcelanosa" />;
