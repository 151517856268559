import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import { container, insta, mainContainer } from "./footer.module.css";
import { Link } from "gatsby";

function Footer() {
  return (
    <div className={mainContainer}>
      <div className={container}>
        <div className={insta}>
          <a href="https://www.instagram.com/herleabtp/?hl=fr">
            <InstagramIcon fontSize="large" />
          </a>
          <h5>Instagram</h5>
        </div>
        <h5>
          <Link to="/mentions">
            Mentions
            <br />
            Légales
          </Link>
        </h5>
        <h5>
          <Link to="/politique">
            Politique
            <br />
            de
            <br />
            confidentilité
          </Link>
        </h5>
        
      </div>
      <div>
        <p>
          Réalisé par <a href="https://www.julien-noel.com">Julien Noël</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
