import React from "react";
import { container, horaires, contact } from "./info.module.css";
import { useStaticQuery, graphql } from "gatsby";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function Info() {
  const data = useStaticQuery(graphql`
    query {
      allGooglePlacesPlace {
        edges {
          node {
            opening_hours {
              weekday_text
            }
          }
        }
      }
    }
  `);

  return (
    <div className={container}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10493.639009469904!2d2.2884646!3d48.8885277!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd4cc23747f0fd571!2sHerlea%20BTP!5e0!3m2!1sfr!2sfr!4v1675763838663!5m2!1sfr!2sfr"
        width="90%"
        height="400"
        style={{ border: 0, margin: 10 }}
        loading="lazy"
        title="herlea btp"
      ></iframe>

      <div className={horaires}>
        <div>
          <h4>Horaires</h4>
          {data.allGooglePlacesPlace.edges[0].node.opening_hours.weekday_text.map(
            (el) => (
              <p>{el}</p>
            )
          )}
        </div>
        <div>
          <h4>Nos Coordonnées</h4>
          <p>
            12 rue Anatole France <br />
            92300 Levallois Perret
          </p>
          <div className={contact}>
          <PhoneIcon /> 
          <p><a href="tel:+33626762650">06.26.76.26.50</a></p>
          </div>
          <div className={contact}><EmailIcon /> <p><a href="mailto:herlea.batiment@gmail.com">herlea.batiment@gmail.com</a></p></div>
          
          
        </div>
      </div>
    </div>
  );
}

export default Info;
